<template>
  <section class="wrapper">
    <div class="logo-wrapper">
      <h1>윤우상<span style="font-weight: 200">블로그</span></h1>
    </div>
    <div class="text">환영합니다! 회원 정보를 입력해 주세요.</div>
    <div class="info-wrapper">
      <div>
        <b>아이디</b>는 고유 식별 코드로 이용되며, 한번 설정 후 변경할 수
        없습니다.
      </div>
      <div>
        <b>닉네임</b>은 블로그 사용자들에게 보여지는 이름으로 프로필 설정에서
        변경 가능합니다.
      </div>
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style src="@/styles/auth/auth.css" scoped></style>
